
import { Component, Vue } from "vue-property-decorator";
import BaseInput from "@/components/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import BaseTextMessageError from "@/components/BaseTextMessageError.vue";

import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";

import { namespace } from "vuex-class";
const UserStore = namespace("UserStore");

@Component({
  components: { BaseInput, BaseButton, BreadCrumbs, BaseTextMessageError },
})
export default class About extends Vue {
  @UserStore.Getter
  private userId!: boolean;

  breadCrumbItems: Array<BreadCrumbDTO> = [];

  errorMessage = "";

  currentPassword = "";
  newPassword = "";
  confirmNewPassword = "";

  async mounted(): Promise<void> {
    this.createBreadCrumbItem();
    const { firstAccess } = this.$route.query;

    if (firstAccess) {
      await this.$swal.fire({
        text: `For security, after the first access you need to update the password.`,
        icon: "warning",
      });
    }

    const input: any = this.$refs.passwordInput;

    if (input) {
      input.focus();
    }
  }

  isValidInput(): boolean {
    this.errorMessage = "";

    if (!this.currentPassword) {
      this.errorMessage = "Enter current password";
      return false;
    }
    if (!this.newPassword) {
      this.errorMessage = "Enter new password";
      return false;
    }
    if (this.newPassword.length < 8) {
      this.errorMessage = "New password must contain at least 8 characters";
      return false;
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.errorMessage =
        "The new password and the confirmation password must be the same";
      return false;
    }

    return true;
  }
  async onChangePasswordClick(): Promise<void> {
    if (!this.isValidInput()) return;

    try {
      const { data } = await this.$api.patch(
        `/library/investors/${this.userId}/password`,
        {
          previousPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.confirmNewPassword,
        }
      );
      await this.$swal.fire({ text: "Password updated successfully." });
      this.cleanFormInfos();
      this.$router.push("/home");
    } catch (error) {
      console.error(error);
    }
  }

  cleanFormInfos(): void {
    this.currentPassword = "";
    this.newPassword = "";
    this.confirmNewPassword = "";
  }

  createBreadCrumbItem(): void {
    this.breadCrumbItems = [
      {
        text: "Change Password",
        goToPath: "",
        clickable: false,
      },
    ];
  }
}
