
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BreadCrumbDTO } from "@/interfaces/BreadCrumb.dto";

@Component({
  components: {},
})
export default class BreadCrumbs extends Vue {
  @Prop({ required: false })
  breadCrumbItemsProps?: Array<BreadCrumbDTO>;

  breadCrumbItems: Array<BreadCrumbDTO> = [
    {
      text: "Home",
      goToPath: "/",
      clickable: true,
    },
  ];

  @Watch("breadCrumbItemsProps")
  changeBreadCrumbProps(): void {
    // eslint-disable-next-line
    if (this.breadCrumbItemsProps) {
      this.breadCrumbItemsProps.forEach((element) => {
        this.breadCrumbItems.push(element);
      });
    }
  }

  onBreadCrumbClick(item: BreadCrumbDTO): void {
    if (item.clickable) {
      this.$router.push(item.goToPath);
    }
  }
  clickableClass(item: BreadCrumbDTO): string {
    return item.clickable ? "clickable-class" : "";
  }
}
